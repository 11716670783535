import { useEffect } from "react";
import { SyntheticEvent, useCallback, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Video = styled.video<{ show: boolean, displaySize: 'mobile' | 'desktop' }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: ${props => props.show && props.displaySize === 'mobile' ? 'initial' : 'none'};

  @media (min-width: 576px) {
    display: ${props => props.show && props.displaySize === 'desktop' ? 'initial' : 'none'};
  }
`;

const GIF = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('./img/egc_banner_mobile_v2.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (min-width: 576px) {
    display: none;
  }
`;

const ApplyButton = styled.button<{ show: boolean }>`
  position: absolute;
  left: calc(50% - 125px);
  top: calc(34% - 50px);
  cursor: pointer;
  width: 100%;
  max-width: 250px;
  height: 50px;
  background-color: transparent;
  border: none;

  @media (min-width: 576px) {
    top: 35%;
    max-width: 450px;
    height: 80px;
    left: calc(50% - 235px);
    display: ${props => props.show ? 'initial' : 'none'};
  }
`;

const loadScript = () => {
  const script = document.createElement('script');
  script.src = '//embed.typeform.com/next/embed.js';
  script.onload = function(e) {
    console.log('script loaded', e);
  };
  document.body.appendChild(script);
}

const Board = ({ show }: { show: boolean }) => {
  const [flashEnd, setFlashEnd] = useState(false);

  const HandleEnd = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setFlashEnd(true);
  }, [])

  useEffect(() => {
    loadScript();
  }, [])

  return (
    <Container style={{ display: show ? 'block' : 'none' }}>
      <Video displaySize="desktop" show={!flashEnd} preload={show ? "metadata" : "none"} autoPlay={show} onEnded={HandleEnd}>
        <source src="./video/egc_banner_4k_v2.mp4#t=0.3" type="video/mp4" />
      </Video>
      <Video displaySize="desktop" show={flashEnd} preload={show ? "metadata" : "none"} autoPlay={show} loop>
        <source src="./video/egirl_loop.mp4" type="video/mp4" />
      </Video>
      <GIF />
      <ApplyButton
        data-tf-popup="x2D3i5oJ"
        data-tf-iframe-props="title=My typeform"
        data-tf-medium="snippet"
        show={flashEnd}
      >
      </ApplyButton>
    </Container>
  )
}

export default Board;
