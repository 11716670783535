
import { MouseEvent, useCallback, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const LogoImg = styled.img`
  position: absolute;
  top: 3px;
  left: 23px;
  width: 150px;
`;

const BgImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./img/enter-mobile.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;

  @media (min-width: 576px) {
    background-image: url('./img/enter.webp');
    background-size: cover;
  }
`;

const BgImgHover = styled.div<{ show: boolean }>`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./img/enter-hover.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (min-width: 576px) {
    display: ${props => props.show ? 'block' : 'none'};;
  }
`;

const AreaDiv = styled.div`
  position: absolute;
  left: 0;
  top: 55%;
  cursor: pointer;
  width: 100%;
  max-width: 660px;
  height: 120px;

  @media (min-width: 576px) {
    left: calc(50% - 290px);
  }
`;

const Landing = ({ onClick }: { onClick: () => void }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    setHovered(true);
  }, [])

  const handleMouseLeave = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    setHovered(false);
  }, [])

  return (
    <Container>
      <BgImg />
      <BgImgHover show={hovered} />
      <LogoImg src="./img/logo-plain.png" />
      <AreaDiv onClick={onClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
    </Container>
  )
}

export default Landing;