import { useState } from 'react';
import './App.css';
import Landing from './pages/landing/Landing';
import Board from './pages/board/Board';

function App() {
  const [entered, setEntered] = useState(false);

  return (
    <div className="App">
      <Landing onClick={() => setEntered(true)} />
      <Board show={entered} />
    </div>
  );
}

export default App;
